import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SendSingleRequest } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { openExternalLink } from '@cfra-nextgen-frontend/shared/src/utils/links';
import { Dispatch, useCallback, useContext, useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { UsageLoggerProps, useUsageLogger } from '@cfra-nextgen-frontend/shared/src/hooks/useUsageLogger';

type IconConfiguration = {
    iconColor: string;
    iconHoverColor: string;
};

type useLinkGetterInputProps<T> = {
    mode: LinkGeneratorModes.openInNewTab | LinkGeneratorModes.openPopup;
    getRequestParams: (entityId?: string, companyId?: string) => Parameters<SendSingleRequest>;
    iconConfiguration?: {
        normal: IconConfiguration;
        error: IconConfiguration;
    };
    getS3PdfUrl: (data: T) => string;
    enableUsageLog?: boolean;
    getPostUsageParams?: (data: T) => any;
    usageLoggerProps?: UsageLoggerProps;
};

export enum LinkGeneratorModes {
    openInNewTab = 'openInNewTab',
    openPopup = 'openPopup'
}

type useLinkGetterOutputProps = IconConfiguration & {
    setEntityId: Dispatch<string>;
    setCompanyId: Dispatch<string>;
    isLoading: boolean;
    isError: boolean;
    htmlLink?: string;
};

type LinkGeneratorState = {
    entityId: string;
    companyId: string;
    isError: boolean;
    htmlLink?: string;
} & IconConfiguration;

export function useLinkGetter<T>({
    mode,
    getRequestParams,
    iconConfiguration,
    getS3PdfUrl,
    enableUsageLog = false,
    usageLoggerProps = {},
    getPostUsageParams
}: useLinkGetterInputProps<T>): useLinkGetterOutputProps {
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const { setRequestBody } = useUsageLogger({ ...usageLoggerProps });

    useEffect(() => {
        if (!sendSingleRequest) {
            throw new Error('useLinkGetter hook exception. sendSingleRequest is not provided.');
        }
    }, [sendSingleRequest]);

    const [{ iconColor, iconHoverColor, entityId, companyId, isError, htmlLink }, setState] = useState<LinkGeneratorState>({
        iconColor: '',
        iconHoverColor: '',
        entityId: '',
        companyId: '',
        isError: false,
        htmlLink: '',
        ...iconConfiguration?.normal,
    });
    const queryResult = sendSingleRequest?.(...getRequestParams(entityId, companyId)) as UseQueryResult<T>;

    const setEntityId = useCallback((entityId: string) => {
        setState((prevState) => ({
            ...prevState,
            entityId,
        }));
    }, []);

    const setCompanyId = useCallback((companyId: string) => {
        setState((prevState) => ({
            ...prevState,
            companyId,
        }));
    }, []);

    if (!sendSingleRequest) {
        throw new Error(
            'useLinkGetter exception. sendSingleRequest is not available in ProjectSpecificResourcesContext.',
        );
    }

    useEffect(() => {
        if ((!entityId && !companyId) || queryResult?.isLoading) {
            return;
        }
    
        const s3PdfUrl = queryResult?.data ? getS3PdfUrl(queryResult.data) : '';

        if (!s3PdfUrl) {
            setState((prevState) => ({
                ...prevState,
                ...iconConfiguration?.error,
                isError: true,
                entityId: '',
                companyId: ''
            }));
            return;
        }
        if (mode !== LinkGeneratorModes.openInNewTab && mode !== LinkGeneratorModes.openPopup) {
            // give some space for hook extension in the feature
            throw new Error('Invalid mode provided to useLinkGetter. Only "openInNewTab" is supported.');
        }
    
        if (mode === LinkGeneratorModes.openPopup) {
            setState((prevState) => ({
                ...prevState,
                entityId: '',
                companyId: '',
                htmlLink: s3PdfUrl,
            }));
        }
        else {
            openExternalLink(s3PdfUrl);
            setState((prevState) => ({
                ...prevState,
                entityId: '',
                companyId: ''
            }));
        }

        if (enableUsageLog && queryResult?.data && getPostUsageParams) {
            const usageReqBody = getPostUsageParams(queryResult.data);
            if (usageReqBody) {
                setRequestBody(usageReqBody, true);
            }
        }
    }, [entityId, queryResult, getS3PdfUrl, mode, iconConfiguration, getPostUsageParams, setRequestBody, enableUsageLog, companyId]);
    

    return { iconColor, iconHoverColor, setEntityId, isLoading: queryResult?.isLoading || false, isError, htmlLink, setCompanyId };
}