/* eslint react/jsx-props-no-spreading: off */ // --> eslint says prop spreading fine for HOC

import MUITypography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';
export default function Typography(props: TypographyProps) {
    return <MUITypography {...props} />;
}

declare module '@mui/material/styles' {
    interface TypographyVariants {
        researchType: React.CSSProperties;
        captionSep: React.CSSProperties;
        cardCaption: React.CSSProperties;
        scoreKeyStyle: React.CSSProperties;
        scoreValueStyle: React.CSSProperties;
        cardSubCaption: React.CSSProperties;
        cardRegular: React.CSSProperties;
        cardLink: React.CSSProperties;
        cardDescriptionTitle: React.CSSProperties;
        cardDescriptionText: React.CSSProperties;
        bespoke: React.CSSProperties;
        headlineTitle: React.CSSProperties;
    }

    interface TypographyVariantsOptions {
        researchType?: React.CSSProperties;
        captionSep?: React.CSSProperties;
        cardCaption?: React.CSSProperties;
        scoreKeyStyle?: React.CSSProperties;
        scoreValueStyle?: React.CSSProperties;
        cardSubCaption?: React.CSSProperties;
        cardRegular?: React.CSSProperties;
        cardLink?: React.CSSProperties;
        cardDescriptionTitle?: React.CSSProperties;
        cardDescriptionText?: React.CSSProperties;
        bespoke?: React.CSSProperties;
        headlineTitle?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        researchType: true;
        captionSep: true;
        cardCaption: true;
        scoreKeyStyle: true;
        scoreValueStyle: true;
        cardSubCaption: true;
        cardRegular: true;
        cardLink: true;
        cardDescriptionTitle: true;
        cardDescriptionText: true;
        bespoke: true;
        headlineTitle: true;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        concerns: true;
        headlines: true;
    }
}

declare module '@mui/material/ButtonBase' {
    interface ButtonBasePropsVariantOverrides {
        headlines: true;
    }
}
