import NewBadge from '@cfra-nextgen-frontend/shared/src/assets/images/NewBadge.svg';
import {
    CellRendererValueProcessor,
    getCellRenderer,
    getCompanyDetailsLinkRenderer,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/renderers';
import { ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { Rating } from '@cfra-nextgen-frontend/shared/src/components/Rating/Rating';
import {
    getFilterKeysToRowLevelFilter,
    getObjectWithFilter,
    getRowLevelFilterCellRenderer,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/components/rowFilters';
import {
    IViewDataFields,
    ScreenerData,
    ScreenerEtfData,
    ScreenerResearchCompanyData,
    ScreenerResearchData,
    Viewdata,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import {
    ComponentFilterParams,
    ViewdataFieldDefinition,
} from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';
import { getMomentObjectFrom, standardDateFormat } from '@cfra-nextgen-frontend/shared/src/utils/time';
import { Box } from '@mui/material';
import { ValueFormatterParams } from 'ag-grid-community';
import moment from 'moment';
import { CellRendererComponentFilters } from '@cfra-nextgen-frontend/shared/src/utils';
import { getExcelExportDateFormat, getExcelNumberFormat } from './excelExport';
import { defaultHeaderTemplate, fillTemplate } from './templates';
import { defaultNoRatingText, defaultNoResultsSymbol, getScreenerValueFormatter } from './valueFormatters';
import { getScreenerNumberFilterValueGetter } from './valueGetters';
import { ReactComponent as PdfIcon } from '@cfra-nextgen-frontend/shared/src/assets/images/PdfIcon.svg';
import { researchLinkGetterParams } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { IconWithLoading } from '@cfra-nextgen-frontend/shared/src/components/Icon/IconWithLoading';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';

function sortViewdataAsc(viewdataFields: Array<IViewDataFields>): Array<IViewDataFields> {
    return viewdataFields.sort((n1, n2) => Object.values(n1)[0].order - Object.values(n2)[0].order);
}

function getHeaderTemplate(header_template?: string, symbol?: string) {
    return !header_template && symbol === '$' ? defaultHeaderTemplate : header_template;
}

function getHeaderName(fieldMetadata: MetadataFieldDefinition, headerTemplate?: string) {
    let result = fieldMetadata.label;

    if (headerTemplate) {
        result =
            fillTemplate({
                templateName: 'header_template',
                template: headerTemplate,
                dataObject: fieldMetadata,
            }) || result;
    }

    return result;
}

type CellRendererValueProcessorProps = {
    fieldMetadata: MetadataFieldDefinition;
    fieldViewdata: ViewdataFieldDefinition;
};

export type CellRendererValueProcessorGetter = (props: CellRendererValueProcessorProps) => CellRendererValueProcessor;

const getCellRendererValueProcessor: CellRendererValueProcessorGetter = ({ fieldMetadata }) => {
    return ({ resultChild, component, resultValue, param }) => {
        switch (component) {
            case 'rating':
                if (!resultChild) {
                    return fieldMetadata.no_value_symbol || defaultNoRatingText;
                }
                return <Rating value={Number(resultChild)} />;

            case 'list':
                return <Box>{resultChild}</Box>;

            case 'new_badge':
                let applyBadge: boolean = false;

                if (
                    param?.component_filter === CellRendererComponentFilters.IS_LAST_ROLLING_HOURS &&
                    resultValue !== defaultNoResultsSymbol
                ) {
                    applyBadge = isLastRollingHours(resultValue, param?.component_filter_params);
                }

                if (!applyBadge) {
                    return resultChild;
                }

                return (
                    <>
                        {resultChild} <Box component='img' src={NewBadge} width='26px' ml='6px' />
                    </>
                );
            case 'document_icon':
                if (typeof resultChild === 'string') {
                    return (
                        <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconWithLoading
                                icon={PdfIcon}
                                companyId={resultChild}
                                useLinkGetterParams={researchLinkGetterParams}
                            />
                        </Grid>
                    );
                }
                break;
            case 'region':
                switch (resultChild) {
                    case 2:
                        return <Box>{'North America'}</Box>;
                    case 3:
                        return <Box>{'Europe'}</Box>;
                    default:
                        return <Box>{'Unknown region'}</Box>;
                }
            default:
                throw new Error(
                    `The component ${component} is not supported by the screener renderer result child wrapper.`,
                );
        }
    };
};

type ExtractFromScreenerDataParams = {
    screenerData: ScreenerEtfData | ScreenerData | ScreenerResearchData | ScreenerResearchCompanyData;
    cardName: string;
    filterKeysToRowLevelFilter?: ReturnType<typeof getFilterKeysToRowLevelFilter>;
    cellRendererValueProcessorGetter?: CellRendererValueProcessorGetter;
};

export function extractFromScreenerData({
    screenerData,
    cardName,
    filterKeysToRowLevelFilter,
    cellRendererValueProcessorGetter = getCellRendererValueProcessor,
}: ExtractFromScreenerDataParams): {
    minWidths: Record<string, number>;
    customFlexibleColumns: Array<string>;
    columnDefs: Array<ColumnDef>;
} {
    const minWidths: Record<string, number> = {};
    const customFlexibleColumns: Array<string> = [];

    function fillMinWidth(fieldViewdata: ViewdataFieldDefinition, headerName: string) {
        if (fieldViewdata.min_width && typeof fieldViewdata.min_width === 'number') {
            minWidths[headerName] = fieldViewdata.min_width;
        }
    }

    function fillCustomFlexibleColumns(fieldViewdata: ViewdataFieldDefinition, headerName: string) {
        if (typeof fieldViewdata.custom_flex === 'boolean' && fieldViewdata.custom_flex) {
            customFlexibleColumns.push(headerName);
        }
    }

    const columnDefs: Array<ColumnDef> = [];

    sortViewdataAsc(screenerData._viewdata.fields).forEach((fieldDict) => {
        const field = Object.keys(fieldDict)[0];
        const fieldViewdata = fieldDict[field];

        if (fieldViewdata.hide) {
            return;
        }

        const fieldMetadata = screenerData._metadata.fields.filter((dict) => Object.keys(dict)[0] === field)[0][field];

        const headerTemplate = getHeaderTemplate(fieldMetadata.header_template, fieldMetadata.symbol);

        const headerNameFromMetadata = getHeaderName(fieldMetadata, headerTemplate);
        const hederNameFromViewData = fieldViewdata.header_name;
        const headerName = hederNameFromViewData || headerNameFromMetadata;

        fillMinWidth(fieldViewdata, headerName);
        fillCustomFlexibleColumns(fieldViewdata, headerName);

        function cellRendererGetter() {
            if (fieldViewdata.link) {
                return getCompanyDetailsLinkRenderer({
                    cfraIdPath: 'id',
                    cardName,
                    urlLinkPattern: fieldMetadata.url_link?.pattern,
                    target: fieldViewdata.link_target,
                });
            }

            if (!fieldViewdata.cell_renderer_params) {
                return;
            }

            const objectWithFilter = getObjectWithFilter(fieldViewdata.cell_renderer_params);

            if (objectWithFilter && filterKeysToRowLevelFilter) {
                return getRowLevelFilterCellRenderer({
                    cellRendererParam: objectWithFilter,
                    filterKeysToRowLevelFilter,
                    fieldMetadata,
                    screenerData,
                });
            }

            return getCellRenderer({
                cellRendererParams: fieldViewdata.cell_renderer_params,
                cardName: cardName,
                urlLinkPattern: fieldMetadata.url_link?.pattern,
                cellRendererValueProcessor: cellRendererValueProcessorGetter({ fieldMetadata, fieldViewdata }),
                noResultsSymbol: fieldViewdata.no_results_symbol,
            });
        }

        const valueFormatter = getScreenerValueFormatter(
            {
                ...fieldMetadata,
                value_template: fieldViewdata.value_template || fieldMetadata.value_template,
                ...(fieldViewdata.format && { format: fieldViewdata.format }),
            },
            screenerData,
            fieldViewdata.no_results_symbol,
        );

        let result: ColumnDef = {
            headerName: headerName,
            field: field,
            filter: 'agTextColumnFilter',
            cellRenderer: cellRendererGetter(),
            valueFormatter: valueFormatter,
            flex: fieldViewdata.flex,
            headerClass: fieldViewdata.header_class,
            cellClass: fieldViewdata.cell_class,
            sort: fieldViewdata.default_sort_order,
            maxWidth: fieldViewdata.max_width,
            pinned: fieldViewdata.pinned,
            comparator: (valueA, valueB, nodeA, nodeB) => {
                if (fieldMetadata.type === 'date' || fieldMetadata.type === 'number') {
                    if (!valueA && valueB) return 1;
                    if (valueA && !valueB) return -1;
                }

                if (fieldMetadata.type === 'date') {
                    const dateFormat = fieldMetadata.input_format || standardDateFormat;
                    return getMomentObjectFrom(valueA, dateFormat).isAfter(getMomentObjectFrom(valueB, dateFormat))
                        ? 1
                        : -1;
                }

                if (fieldMetadata.type === 'number') {
                    return valueA > valueB ? 1 : -1;
                }

                // convert to string
                const formattedValueA = String(
                    valueFormatter({ data: nodeA.data, colDef: result } as ValueFormatterParams),
                );
                const formattedValueB = String(
                    valueFormatter({ data: nodeB.data, colDef: result } as ValueFormatterParams),
                );

                // always move down empty values
                if (formattedValueA === defaultNoResultsSymbol && formattedValueB !== defaultNoResultsSymbol) return 1;
                if (formattedValueB === defaultNoResultsSymbol && formattedValueA !== defaultNoResultsSymbol) return -1;

                return formattedValueA?.toLowerCase?.() > formattedValueB?.toLowerCase?.() ? 1 : -1;
            },
            exportType: fieldViewdata.export_cell_type,
            unSortIcon: fieldViewdata.show_unsort_icon,
        };

        if (fieldMetadata.type === 'number') {
            result = {
                ...result,
                ...(!fieldViewdata.cell_type && {
                    type: 'rightAligned',
                }),
                filter: 'agNumberColumnFilter',
                filterValueGetter: getScreenerNumberFilterValueGetter(fieldMetadata),
                excelExportNumberFormat: getExcelNumberFormat(fieldMetadata),
            };
        }

        if (fieldMetadata.type === 'date') {
            result = {
                ...result,
                excelExportDateFormat: fieldMetadata.format
                    ? getExcelExportDateFormat(fieldMetadata.format)
                    : undefined,
            };
        }

        columnDefs.push(result);
    });

    return { minWidths, customFlexibleColumns, columnDefs };
}

function isLastRollingHours(date: moment.MomentInput, filter_params: ComponentFilterParams = {}) {
    const { hours = 0 } = filter_params;
    if (date && moment(date).isValid() && moment(date).isAfter(moment().subtract(hours, 'hours'))) {
        return true;
    }
    return false;
}
