import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { ScreenerAnalyticsServiceContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/Context';
import {
    SavedItemOperations,
    getSelectedItem,
    getWatchlistEtfsData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/utils';
import {
    InstitutionalWatchListRow,
    WatchListRow,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { useContext, useEffect, useState } from 'react';
import SaveModalForm from '@cfra-nextgen-frontend/shared/src/components/SavedItem/SaveModalForm';
import { getSavedWatchLists, getWatchListData } from './WatchListUtils';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import {
    CreateSavedItemResponseTypes,
    SavedItemListResponseTypes,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import {
    getSavedItemsByTypeConfig,
    createSavedItemConfig,
} from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { FundynamixWatchListSnack, InstitutionalWatchListSnack } from './WatchListSnack';

export default function CreateWatchListComponent({
    placeholder,
    onSuccessCallback,
    onIsDirtyChange,
    externalViewWatchlist,
    watchListData,
    modalTitle,
    savedItemType,
}: {
    placeholder?: string;
    onSuccessCallback?: () => void;
    onIsDirtyChange: (v: boolean) => void;
    externalViewWatchlist?: boolean;
    watchListData?: Array<WatchListRow | InstitutionalWatchListRow>;
    modalTitle?: string;
    savedItemType: UserSavedItemsLookupID;
}) {
    const { selectedRowsData } = useContext(AgGridSelectedRowsContext);
    const { registerAction } = useContext(ScreenerAnalyticsServiceContext);
    const [watchlistName, setWatchlistName] = useState('');
    const [watchlistData, setWatchlistData] = useState<undefined | (WatchListRow | InstitutionalWatchListRow)[]>(
        undefined,
    );
    const enableCreateWatchlist = watchlistData !== undefined && watchlistName !== '';
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const saveWatchList = sendSingleRequest<CreateSavedItemResponseTypes>(
        {
            version: 'v2', //don't remove. The query will error without it.
            requestBody: {
                name: watchlistName,
                saved_item_lid: savedItemType,
                value: {
                    values: watchlistData,
                },
            },
            config: {
                enabled: enableCreateWatchlist,
            },
        },
        createSavedItemConfig,
    );

    const getAllWatchLists = sendSingleRequest<SavedItemListResponseTypes>(
        { types: [savedItemType] },
        getSavedItemsByTypeConfig,
    );

    useEffect(() => {
        if (saveWatchList.isSuccess && watchlistName && enableCreateWatchlist) {
            if (savedItemType === UserSavedItemsLookupID.Watchlist) {
                const analyticsEtfsData = getWatchlistEtfsData(
                    (saveWatchList.data.data.value as { values: WatchListRow[] }).values,
                );

                const savedItemOperation = SavedItemOperations.SaveWatchlist;

                const registerActionParams = {
                    action: `${savedItemOperation} : ${getSelectedItem(saveWatchList.data.data)}`,
                    cardName: modalTitle,
                    savedItemName: saveWatchList.data.data.name,
                    savedItemId: saveWatchList.data.data.id,
                    savedItemOperation: savedItemOperation,
                    savedItemLength: analyticsEtfsData.length,
                    list2: analyticsEtfsData,
                };

                if (registerAction) {
                    registerAction(registerActionParams);
                } else {
                    globalThis.analytics?.registerAction?.(registerActionParams);
                }
            }

            getAllWatchLists.refetch();
            onSuccessCallback?.();
        }
    }, [
        getAllWatchLists,
        onSuccessCallback,
        enableCreateWatchlist,
        modalTitle,
        registerAction,
        saveWatchList.data?.data,
        saveWatchList.isSuccess,
        watchlistName,
        savedItemType,
    ]);

    const onWatchListNameChange = (newValue: string) => setWatchlistName(newValue);

    function handleSaveClick() {
        if (savedItemType === UserSavedItemsLookupID.InstitutionalWatchlist) {
            setWatchlistData(watchListData);
        } else {
            setWatchlistData(watchListData === undefined ? getWatchListData(selectedRowsData) : watchListData);
        }
    }

    const snackProps = {
        watchlistName: watchlistName,
        message: `${watchlistName} created successfully`,
        watchListResult: saveWatchList,
        enableWatchlist: enableCreateWatchlist,
        externalViewWatchlist
    };

    let SnackComponent =
        savedItemType === UserSavedItemsLookupID.InstitutionalWatchlist
            ? InstitutionalWatchListSnack
            : FundynamixWatchListSnack;

    const savedWatchLists = getSavedWatchLists(getAllWatchLists);
    return (
        <>
            <SnackComponent {...snackProps} />
            <SaveModalForm
                placeholder={placeholder}
                saveType={'Save WatchList'}
                onPositiveAction={handleSaveClick}
                positiveBtnText='Save'
                existingNames={savedWatchLists.map((watchlist: any) => watchlist.name)}
                onWatchListNameChange={onWatchListNameChange}
                onIsDirtyChange={onIsDirtyChange}
            />
        </>
    );
}
