import { SavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedItem';
import { Dispatch } from 'react';

type WatchlistActionTypes =
    | 'OpenSaveWatchlistModal'
    | 'CloseSaveWatchlistModal'
    | 'SetSelectedWatchlist'
    | 'ClearSelectedWatchlist'
    | 'RenameSelectedWatchlist'
    | 'SetSelectedWatchlistDetails';

export type WatchlistAction = {
    type: WatchlistActionTypes;
    payload?: any;
};

export type SavedItemDetailsType = {
    value: any;
    is_active: boolean;
    created_date: string;
} & SavedItemTypes;

export type WatchListRow = {
    id: string;
    composite_name: string;
    composite_ticker: string;
    type?: string;
};

export enum SelectedWatchlistState {
    NONE = 'NONE',
    LOADING = 'LOADING',
    SELECTED = 'SELECTED',
}

export type WatchlistState = {
    openModal: boolean;
    selectedWatchlist: SavedItemDetailsType | undefined;
    selectedWatchlistState: SelectedWatchlistState;
};

export type WatchListContextProps = {
    watchListState: WatchlistState;
    watchListDispatcher: Dispatch<WatchlistAction>;
};

export type InstitutionalWatchListRow = {
    id: string;
    composite_name: string;
    cfra_security_trading_id?: string;
    cfra_company_id?: string;
};
