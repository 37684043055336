import { CircularProgress, Box } from '@mui/material';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';
import { useLinkGetter } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { useContext, useMemo } from 'react';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { useParams } from 'react-router-dom';
type CompanyParams = { company_id?: string; security_id?: string };

interface IconWithLoadingProps<T> {
    icon: React.FC<React.SVGProps<SVGSVGElement>>;
    rowId?: string;
    companyId?: string;
    useLinkGetterParams: Parameters<typeof useLinkGetter<T>>;
    applyUseLinkGetter?: boolean;
    isOuterLoading?: boolean;
    handleClick?: () => void;
}

export function IconWithLoading<T>({
    icon,
    rowId,
    companyId,
    useLinkGetterParams,
    applyUseLinkGetter = true,
    isOuterLoading = false,
    handleClick,
}: IconWithLoadingProps<T>) {
    const { iconColor, iconHoverColor, setEntityId, setCompanyId, isLoading } = useLinkGetter<T>(...useLinkGetterParams);
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const companyParams = useParams<keyof CompanyParams>() as CompanyParams;
    companyParams.company_id = companyId;

    const onClick = useMemo(() => {
        return async () => {
            if (rowId) {
                setEntityId(rowId);
            }

            if (companyId) {
                setCompanyId(companyId);
            }
        };
    }, [ rowId, setEntityId, setCompanyId, companyId]);

    const handleBoxClick = applyUseLinkGetter || handleClick ? onClick : () => {};

    return (
        <Box
            sx={{
                width: '18px',
                height: '23.75px',
                display: 'flex',
                alignItems: 'center',
            }}      
            onClick={handleBoxClick}
        >
            {isOuterLoading || isLoading ? (
                <CircularProgress size={15} sx={{ color: 'primary.main' }} />
            ) : (
                <CustomSvgIcon
                    component={icon}
                    viewBox="0 0 18 18"
                    sx={{
                        width: '18px',
                        color: iconColor,
                        '&:hover': {
                            fill: iconHoverColor,
                            color: iconHoverColor,
                        },
                    }}
                />
            )}
        </Box>
    );
}
