import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { queryClient } from '@cfra-nextgen-frontend/shared/src/lib/react-query-client';
import {
    QueryFnType,
    SearchByParams,
    UseDataType,
    UseMultipleDataType,
    getFullRequestQuery,
    getRequestQuery,
} from '@cfra-nextgen-frontend/shared/src/utils/api';
import { chunkArray } from '@cfra-nextgen-frontend/shared/src/utils/arrays';
import { ApiNames, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { UseQueryResult } from 'react-query';
import { useInfiniteQuery, GetNextPageParamFunction } from 'react-query';

export type GetScreenerData = ReturnType<typeof determineGetScreenerData>;
export type GetScreenerDataSSR = ReturnType<typeof determineGetScreenerDataSSR>;
export type GetFiltersData = ReturnType<typeof determineGetFiltersData>;
export type SendSingleRequest = ReturnType<typeof determineSendSingleRequest>;
export type SendSingleInfiniteRequest = ReturnType<typeof determineSendSingleInfiniteRequest>;
export type OperateEntity = ReturnType<typeof determineOperateEntity>;
export type OperateMultipleEntities = ReturnType<typeof determineOperateMultipleEntities>;

const defaultScreenerDataParams = {
    includeData: true,
    includeMetadata: true,
};

export const determineGetScreenerData = (UseData: UseDataType, apiName: ApiNames) =>
    function (searchByParams: SearchByParams, queryKeyFirstElement?: string) {
        const { processResponse, ...restSearchByParams } = searchByParams;
        const extendedSearchByParams = { ...defaultScreenerDataParams, ...restSearchByParams };
        const screenerPath = `internal/screener/${extendedSearchByParams.path}`;
        const requestQuery = getRequestQuery(extendedSearchByParams, screenerPath);
        const queryKey = [
            queryKeyFirstElement || 'getScreenerData',
            ...Object.values(extendedSearchByParams),
            screenerPath,
        ];

        let data = UseData<ScreenerData>({
            apiName,
            requestQuery,
            queryKey,
            requestType: RequestTypes.POST,
            requestBody: extendedSearchByParams.requestBody || {},
            config: restSearchByParams.config,
        });
        if (processResponse) {
            return processResponse?.(data);
        }
        return data;
    };

export const determineGetScreenerDataSSR = (getData: QueryFnType, apiName: ApiNames, pathPrefix?: string) =>
    function (searchByParams: SearchByParams) {
        const extendedSearchByParams = { ...defaultScreenerDataParams, ...searchByParams };
        const _pathPrefix = pathPrefix === undefined ? 'internal/screener': pathPrefix;
        const screenerPath = `${_pathPrefix}/${extendedSearchByParams.path}`;
        const requestQuery = getRequestQuery(extendedSearchByParams, screenerPath);

        return getData<ScreenerData>({
            apiName,
            requestQuery: getFullRequestQuery({ apiName, requestQuery }),
            requestType: RequestTypes.POST,
            requestBody: searchByParams.requestBody || {},
        });
    };

export const determineGetFiltersData = ({
    UseData,
    apiName,
    filtersPath,
}: {
    UseData: UseDataType;
    apiName: ApiNames;
    filtersPath: string;
}) =>
    function (searchByParams: SearchByParams, queryKeyFirstElement?: string) {
        const _filtersPath = `${filtersPath}/${searchByParams.path || ''}`;

        return UseData<FiltersData>({
            requestQuery: getRequestQuery(searchByParams, _filtersPath),
            queryKey: [queryKeyFirstElement || 'getFiltersData', ...Object.values(searchByParams), _filtersPath],
            apiName,
            requestType: RequestTypes.POST,
            requestBody: searchByParams.requestBody || {},
            config: searchByParams.config,
        });
    };

export const determineSendSingleRequest = function ({ UseData }: { UseData: UseDataType }) {
    return function <T>(
        searchByParams: SearchByParams,
        config: {
            apiName: ApiNames;
            path?: string;
            queryKeyFirstElement: string;
            requestType: RequestTypes;
            invalidateQuery?: boolean;
        },
    ) {
        const queryKey = [config.queryKeyFirstElement, ...Object.values(searchByParams), config.path];
        if (config.invalidateQuery || searchByParams.doFreshRequest)
            queryClient.invalidateQueries(queryKey);
        return UseData<T>({
            apiName: config.apiName,
            config: searchByParams.config,
            queryKey,
            requestBody: searchByParams.requestBody,
            requestQuery: getRequestQuery(searchByParams, config.path),
            requestType: config.requestType,
        });
    };
};

export const determineSendSingleInfiniteRequest = function ({ getData }: { getData: QueryFnType }) {
    return function <T>(
        searchByParams: SearchByParams,
        config: {
            apiName: ApiNames;
            queryKeyFirstElement: string;
            requestType: RequestTypes;
            getNextPageParam: GetNextPageParamFunction<T>;
        },
    ) { 
        // exclude config from dependencies array, because
        // such config props (for ex. enabled) shouldn't influence on the query cashing
        const { config: searchByParamsConfig, ...restSearchByParams } = searchByParams;
        return useInfiniteQuery(
            [config.queryKeyFirstElement, ...Object.values(restSearchByParams)],
            ({ pageParam = 0 }) => {

                let requestQuery = getRequestQuery(
                    {
                        ...restSearchByParams,
                        from: pageParam,
                    },
                    restSearchByParams.path,
                );

                requestQuery = getFullRequestQuery({ apiName: config.apiName, requestQuery });
                
                return getData<T>({
                    requestQuery,
                    requestType: config.requestType,
                    apiName: config.apiName,
                    requestBody: restSearchByParams.requestBody,
                });
            },
            {
                getNextPageParam: config.getNextPageParam,
                enabled: searchByParamsConfig?.enabled,
            },
        );
    };
};

export const determineOperateEntity = ({
    UseData,
    UseMultipleData,
    apiName,
    entityPath,
    queryKeyFirstElement,
}: {
    UseData: UseDataType;
    UseMultipleData?: UseMultipleDataType;
    apiName: ApiNames;
    entityPath?: string;
    queryKeyFirstElement?: string;
}) =>
    function ({
        searchByParams,
        requestType,
        invalidate = true,
        singeRequestThreshold,
    }: {
        searchByParams: SearchByParams;
        requestType: RequestTypes;
        invalidate?: boolean;
        singeRequestThreshold?: number;
    }): UseQueryResult<any> | Array<UseQueryResult<any>> {
        const _entityPath = entityPath || `internal/entity/${searchByParams.path}`;
        const _queryKeyFirstElement = queryKeyFirstElement || 'operateEntity';

        if (singeRequestThreshold !== undefined) {
            if (!UseMultipleData) {
                throw new Error('determineOperateEntity exception. UseMultipleData is required for multiple requests.');
            }

            let chunks: Array<any> = [];

            if (Array.isArray(searchByParams.requestBody)) {
                chunks = chunkArray(searchByParams.requestBody, singeRequestThreshold);
            } else {
                chunks = [[searchByParams.requestBody]];
            }

            const queryParams = chunks.map((requestBodyChunk) => {
                const queryKey = [_queryKeyFirstElement, ...requestBodyChunk, _entityPath, requestType, invalidate];

                if (invalidate) {
                    queryClient.invalidateQueries(queryKey);
                }

                return {
                    apiName,
                    requestType,
                    requestQuery: _entityPath,
                    requestBody: requestBodyChunk,
                    queryKey,
                    config: searchByParams.config,
                };
            });

            return UseMultipleData<any>(queryParams);
        }

        const queryKey = [
            _queryKeyFirstElement,
            ...Object.values(searchByParams),
            _entityPath,
            requestType,
            invalidate,
        ];
        if (invalidate) {
            queryClient.invalidateQueries(queryKey);
        }

        return UseData<any>({
            apiName,
            requestType,
            requestQuery: _entityPath,
            requestBody: searchByParams.requestBody,
            queryKey,
            config: searchByParams.config,
        });
    };

export const determineOperateMultipleEntities = (UseMultipleData: UseMultipleDataType, apiName: ApiNames) =>
    function (params: { searchByParams: SearchByParams; requestType: RequestTypes; invalidate?: boolean }[]) {
        return UseMultipleData<any>(
            params?.map((param) => {
                const entityPath = `internal/entity/${param.searchByParams.path}`;
                const queryKey = [
                    'operateMultipleEntities',
                    ...Object.values(param.searchByParams),
                    entityPath,
                    param.requestType,
                    param.invalidate,
                ];
                if (param.invalidate) {
                    queryClient.invalidateQueries(queryKey);
                }
                return {
                    apiName,
                    requestType: param.requestType,
                    requestQuery: entityPath,
                    requestBody: param.searchByParams.requestBody,
                    queryKey,
                    config: param.searchByParams.config,
                };
            }),
        );
    };
