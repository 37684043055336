import { DefaultCFRASnack } from '@cfra-nextgen-frontend/shared';
import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { RoundedTextButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/RoundedTextButton';
import { StyledFormLabel } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { ScreenerAnalyticsServiceContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/Context';
import {
    SavedItemOperations,
    getSelectedItem,
    getWatchlistEtfsData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/analytics/ScreenerAnalyticsServiceContextProvider/utils';
import {
    InstitutionalWatchListRow,
    WatchListRow,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/types';
import { SnackMessageForm } from '@cfra-nextgen-frontend/shared/src/components/Snack/SnackMessageForm';
import { UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { Autocomplete, Box, TextField, ThemeProvider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Theme, getSavedWatchLists, roundedTextButtonTheme, getWatchListData } from './WatchListUtils';
import { SavedItemListResponseTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import {
    getSavedItemsByTypeConfig,
    updateSavedItemByIdConfig,
    getSavedItemsByIdConfig,
} from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { FundynamixWatchListSnack, InstitutionalWatchListSnack } from './WatchListSnack';

export default function UpdateWatchListComponent({
    registerAnalytics,
    onIsDirtyChange,
    onSuccessCallback,
    handleDuplicateIds,
    externalViewWatchlist,
    watchListData,
    modalTitle,
    savedItemType,
}: {
    registerAnalytics: (action: string) => void;
    onIsDirtyChange: (v: boolean) => void;
    onSuccessCallback?: () => void;
    handleDuplicateIds?: boolean;
    externalViewWatchlist?: boolean;
    watchListData?: Array<WatchListRow | InstitutionalWatchListRow>;
    modalTitle: string;
    savedItemType: UserSavedItemsLookupID;
}) {
    const { selectedRowsData } = useContext(AgGridSelectedRowsContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [updateWatchlist, setUpdateWatchlist] = useState({ id: undefined, name: '' });
    const [watchlistData, setWatchlistData] = useState<undefined | WatchListRow[] | InstitutionalWatchListRow[]>(
        undefined,
    );
    const { registerAction } = useContext(ScreenerAnalyticsServiceContext);

    const ShowSnack = DefaultCFRASnack(enqueueSnackbar);

    const enableUpdateWatchlist = watchlistData !== undefined && typeof updateWatchlist.id === 'number';

    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const getWatchlistByIdQry = sendSingleRequest<any>(
        {
            savedItems: updateWatchlist.id || -1,
            config: { enabled: typeof updateWatchlist.id === 'number' },
        },
        getSavedItemsByIdConfig,
    );

    const updateWatchListQueryResult = sendSingleRequest<any>(
        {
            savedItems: updateWatchlist.id || -1,
            requestBody: {
                name: updateWatchlist.name,
                value: {
                    values: watchlistData,
                },
            },
            config: {
                enabled: enableUpdateWatchlist,
            },
        },
        updateSavedItemByIdConfig,
    );

    const getAllWatchLists = sendSingleRequest<SavedItemListResponseTypes>(
        { types: [savedItemType] },
        getSavedItemsByTypeConfig,
    );

    useEffect(() => {
        if (updateWatchListQueryResult.isSuccess && updateWatchlist.name && enableUpdateWatchlist) {
            if (savedItemType === UserSavedItemsLookupID.Watchlist) {
                const analyticsEtfsData = getWatchlistEtfsData(
                    (updateWatchListQueryResult.data.data.value as { values: WatchListRow[] }).values,
                );
                const analyticsSelectedEtfsData = getWatchlistEtfsData(
                    watchListData === undefined
                        ? selectedRowsData.map((item) => ({
                              composite_ticker: item?.fund_profile?.composite_ticker,
                              composite_name: item?.fund_profile?.composite_name,
                              id: item?.fund_profile?.identifiers?.cfra_id || item?.id,
                          }))
                        : (watchListData as WatchListRow[]),
                );

                const savedItemOperation = SavedItemOperations.AddToExistingWatchlist;

                const registerActionParams = {
                    action: `${savedItemOperation} : ${getSelectedItem(updateWatchListQueryResult.data.data)}`,
                    cardName: modalTitle,
                    savedItemName: updateWatchListQueryResult.data.data.name,
                    savedItemId: updateWatchListQueryResult.data.data.id,
                    savedItemOperation: savedItemOperation,
                    savedItemLength: `${analyticsSelectedEtfsData.length} : ${analyticsEtfsData.length}`,
                    list1: analyticsEtfsData,
                    list2: analyticsSelectedEtfsData,
                };

                if (registerAction) {
                    registerAction(registerActionParams);
                } else {
                    globalThis.analytics?.registerAction?.(registerActionParams);
                }
            }

            onSuccessCallback?.();
            getAllWatchLists.refetch();
        }
    }, [
        updateWatchListQueryResult,
        updateWatchlist.name,
        getAllWatchLists,
        enableUpdateWatchlist,
        onSuccessCallback,
        modalTitle,
        registerAction,
        selectedRowsData,
        watchListData,
        savedItemType,
    ]);

    const savedWatchLists = getSavedWatchLists(getAllWatchLists);

    const UpdateWatchListData = (e: any, newValue: any) => {
        setUpdateWatchlist({
            name: (newValue && newValue.name) || undefined,
            id: (newValue && newValue.id) || undefined,
        });
        onIsDirtyChange(e);
    };

    async function handleOnUpdateClick() {
        const { isRefetching, isRefetchError, data } = await getWatchlistByIdQry.refetch();
        if (!isRefetching && !isRefetchError && data) {
            const values = data?.data?.value?.values || [];
            let existingIds: Array<string>;
            let newSelectedValues: Array<WatchListRow | InstitutionalWatchListRow>;

            switch (savedItemType) {
                case UserSavedItemsLookupID.Watchlist:
                    newSelectedValues = (
                        watchListData === undefined ? getWatchListData(selectedRowsData) : watchListData
                    ) as Array<WatchListRow>;

                    break;
                case UserSavedItemsLookupID.InstitutionalWatchlist:
                    newSelectedValues = watchListData as Array<InstitutionalWatchListRow>;
                    break;
                default:
                    newSelectedValues = [];
                    break;
            }

            existingIds = values.map((obj: WatchListRow | InstitutionalWatchListRow) => obj.id);

            if (handleDuplicateIds && newSelectedValues !== undefined) {
                const duplicateIds: Array<string> = [];

                newSelectedValues.forEach((obj) => {
                    if (existingIds.includes(obj.id)) {
                        let name = 'composite_ticker' in obj ? obj.composite_ticker : obj.id;

                        const snackbarKey = ShowSnack(
                            SnackMessageForm({
                                message: `${name} is already in ${updateWatchlist.name}. Please select a different Watchlist.`,
                                onActionBtnClick: () => {
                                    if (snackbarKey) {
                                        closeSnackbar(snackbarKey);
                                    }
                                },
                            }),
                        );
                        duplicateIds.push(obj.id);
                    }
                });

                duplicateIds.forEach(
                    (id) => (newSelectedValues = newSelectedValues.filter((value) => value.id !== id)),
                );

                if (newSelectedValues.length === 0) {
                    return;
                }
            }

            const mergedArray: Array<WatchListRow | InstitutionalWatchListRow> = [...values, ...newSelectedValues];

            const uniqueObjects = mergedArray.reduce(
                (acc: { [key: string]: WatchListRow | InstitutionalWatchListRow }, obj) => {
                    const keyValue = obj['id'];
                    if (!acc[keyValue]) {
                        acc[keyValue] = obj;
                    }
                    return acc;
                },
                {},
            );

            if (uniqueObjects) setWatchlistData(Object.values(uniqueObjects));
        }
    }
    const snackProps = {
        watchlistName: updateWatchlist.name,
        message:
            savedItemType === UserSavedItemsLookupID.InstitutionalWatchlist
                ? `You added company to ${updateWatchlist.name} watchlist`
                : `You added tickers to ${updateWatchlist.name} watchlist`,
        watchListResult: updateWatchListQueryResult,
        enableWatchlist: enableUpdateWatchlist,
        externalViewWatchlist
    };

    let SnackComponent =
        savedItemType === UserSavedItemsLookupID.InstitutionalWatchlist
            ? InstitutionalWatchListSnack
            : FundynamixWatchListSnack;

    return (
        <>
            <SnackComponent {...snackProps} />
            <Box sx={{ paddingTop: '20px' }}>
                <StyledFormLabel sx={{ fontSize: '16px', mt: '6px', mb: '10px' }}>My Watchlists</StyledFormLabel>
                <ThemeProvider theme={Theme}>
                    <Autocomplete
                        options={savedWatchLists}
                        disableListWrap
                        slotProps={{
                            popper: {
                                sx: { zIndex: 3000 },
                            },
                        }}
                        onChange={UpdateWatchListData}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} placeholder='Select' />}
                        disabled={getWatchlistByIdQry.isLoading}
                    />
                </ThemeProvider>
            </Box>
            <Box sx={{ paddingTop: '33px', display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <RoundedTextButton
                    type='submit'
                    theme={roundedTextButtonTheme}
                    buttonText={'Update'}
                    disabled={typeof getWatchlistByIdQry.data?.data?.id !== 'number'}
                    onClickCallback={() => {
                        registerAnalytics('click on Save');
                        handleOnUpdateClick();
                    }}
                />
            </Box>
        </>
    );
}
