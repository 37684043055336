import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { LinkGeneratorModes, useLinkGetter } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { getUsageApiReqBody } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ApiNames, RequestTypes, UsageRequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';

export const researchLinkGetterParams: Parameters<typeof useLinkGetter<ScreenerResearchData>> = [
    {
        mode: LinkGeneratorModes.openInNewTab,
        getRequestParams: (entityId, companyId = undefined) => {
            const isCompanyRequest = Boolean(companyId);
            const view = isCompanyRequest ? 'research_pdf_url' : 'pdf';
            const filters = isCompanyRequest
                ? {
                    'insights.research_report.research_type_id': { values: [19] },
                    'insights.research_report_security__company.research_report_security.company.cfra_company_id': { values: [companyId] },
                }
                : {
                    'insights.research_report._id': { values: [entityId] },
                };
                
            return [
                {
                    from: 0,
                    size: 1,
                    view: view,
                    includeData: isCompanyRequest,
                    includeMetadata: false,
                    securityType: 'research',
                    requestBody: { filters: { values: filters } },
                    config: { enabled: isCompanyRequest ? Boolean(companyId) : Boolean(entityId) },
                },
                {
                    requestType: RequestTypes.POST,
                    path: 'research/screener',
                    queryKeyFirstElement: 'researchScreenerQuery',
                    apiName: ApiNames.Research,
                },
            ];
        },
        iconConfiguration: {
            normal: {
                iconColor: '#002B5A',
                iconHoverColor: '#007AB9',
            },
            error: {
                iconColor: 'rgb(220, 0, 0)',
                iconHoverColor: 'rgb(220, 0, 0)',
            },
        },
        getS3PdfUrl: (data) => {
            if (data?.results?.research?.length === 0) {
                return '';
            }
            return data?.results?.research?.[0]?.research_report?.s3_pdf_url;
        },
        enableUsageLog: true,
        usageLoggerProps: {
            invalidateUsage: true,
            actionType: UsageRequestTypes.ReportViewed
        },
        getPostUsageParams: (data) => {
            if (data?.results?.research?.length === 0) {
                return;
            }

            const research = data?.results?.research?.[0]
            let researchTypeId = research['research_report']?.['research_type_id']
            if (!researchTypeId){
                researchTypeId = research['research_report.research_type_id']
            }

            return getUsageApiReqBody(UsageRequestTypes.ReportViewed, {
                research_type_id: researchTypeId,
                id: research.id,
            })
        },
    },
];
