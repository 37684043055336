import { Button, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fontFamilies } from '../../utils';
import { fFunction } from './types';

export type ButtonProps = {
    onClick?: fFunction;
    text?: string;
    disabled?: boolean;
    href?: string;
    target?: string;
    disableRipple?: boolean;
    sx?: SxProps;
    startIcon?: string | React.ReactElement;
    name?: string;
    content?: React.ReactNode;
};

export const LinkButton = styled(Button)(({ theme }) => ({
    color: '#007BB8',
    fontFamily: fontFamilies.GraphikRegular,
    fontSize: `15px`,
    textTransform: 'none',
    padding: '0px',
    borderRadius: '0px',
    '&:hover': {
        backgroundColor: '#FFF',
    },
}));

export function ETFLinkButton(props: ButtonProps) {
    const { onClick, disabled, text, name, content, ...rest } = props;
    return (
        <LinkButton size='small' disabled={disabled} onClick={onClick} name={name || text} {...rest}>
            {text || content}
        </LinkButton>
    );
}
